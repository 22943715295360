
.z-depth-1 {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.z-depth-2 {
    -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
}
.z-depth-3 {
    -webkit-box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
}
.z-depth-4 {
    -webkit-box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
    box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
}
.z-depth-5 {
    -webkit-box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
}

.raise-1 {
    z-index: 1;
}

.raise-2 {
    z-index: 2;
}

.raise-3 {
    z-index: 3;
}
 
.raise-4 {
    z-index: 4;
}

.lower-1 {
    z-index: -1;
}

.lower-1 {
    z-index: -2;
}

.lower-3 {
    z-index: -3;
}

.lower-4 {
    z-index: -4;
}

.flow-icon {
    position: absolute;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
    top: -40px;
    right: 0px;
    width: 80px;
    height: 80px;
    z-index: 1;
}

@include media-breakpoint-down(sm) {
    .flow-icon {
        position: absolute;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 5px;
        top: -20px;
        right: 0px;
        width: 60px;
        height: 60px;
        z-index: 1;
    }

    .flow-icon-header {
        max-width: 80%;
    }
    
}

.confirm-mx-close {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99999999999;
    background: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 24px;

    .message {
        flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }

    .btn {
        margin-left: 0;
        margin-right: 0;
        padding-top: $spacer;
        padding-bottom: $spacer;
        background-color: white;
        border: solid 1px $gray-800;
        border-radius: 8px;

        &:first-child {
            margin-bottom: $spacer * 2;
        }
    }

}

.employment-circle {

    .logo-circle {
        border: solid 3px white;


        color: $white;
        margin: 2px;
        border-radius: 100%;
        width: 40px;
        text-align: center;
        height: 40px;
        -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

        img {
            border-radius: 100%;
        }
    }

    .employment-circle-inner {
        border: solid 3px white;
        background-color: $green;
        padding-top: 5px;
        color: $white;
        margin: 2px;
        border-radius: 100%;
        width: 40px;
        text-align: center;
        height: 40px;
        -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

        &.employment-circle-inner-green {
            background-color: rgb(77, 179, 106);
        }
        &.employment-circle-inner-yellow {
            background-color: rgb(235, 174, 17);
        }
        &.employment-circle-inner-orange {
            background-color: rgb(235, 121, 17);
        }
        &.employment-circle-inner-pink {
            background-color: rgb(224, 114, 114);
        }
        &.employment-circle-inner-blue {
            background-color: rgb(88, 155, 255);
        }
        &.employment-circle-inner-purple {
            background-color: rgb(131, 88, 255);
        }

    }

}

#archk-search-modal {
    .card {
        margin-bottom: 0;
        box-shadow: none !important;

        .card-header {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
        .card-footer {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
}

.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
    border-radius: 6px;
    border-radius: 12px;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
